<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>岗位管理</el-breadcrumb-item>
        <el-breadcrumb-item>岗位模型</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="1">企业</el-menu-item>
        <el-menu-item index="0">平台</el-menu-item>
      </el-menu>
    </div>
    <div
      class="yt-container yt-container-flex"
      :class="{
        'is-pack-up': isPackUp
      }"
    >
      <div class="yt-content-tree" :class="{ 'has-footer': activeIndex !== '0' }">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>岗位模型</p>
          </div>
          <el-input v-model="postKeyword" class="yt-search search" placeholder="通过名称筛选" @keydown.enter.native="toFilterNodes">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNodes"></i>
          </el-input>
          <div class="tree has-tool" style="height: calc(100% - 146px); margin-top: 0">
            <el-tree
              ref="post-tree"
              :data="postData"
              :props="postProps"
              node-key="id"
              :highlight-current="true"
              :filter-node-method="filterNode"
              @node-click="handleNodeClick"
            >
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" :open-delay="1000" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label }}</div>
                </el-tooltip>
                <div v-if="activeIndex === '1'" class="tools">
                  <span class="tool-button" @click.stop="toUpdatePost(data)">修改</span>
                  <span class="tool-button" @click.stop="toDeletePost(data)">删除</span>
                </div>
              </div>
            </el-tree>
          </div>
          <div v-if="activeIndex !== '0'" class="footer">
            <el-button style="width: 138px" size="small" @click="toCreatePost">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <p>新建岗位</p>
              </div>
            </el-button>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <div class="yt-header-toolbar" style="justify-content: flex-end; margin-right: 11px">
          <el-button type="primary" size="small" @click="toAddSkill" v-if="activeIndex !== '0'">
            <div>
              <i class="el-icon-circle-plus-outline" />
              <p>添加技能</p>
            </div>
          </el-button>
          <el-button type="primary" size="small" @click="toCopyPost">
            <div>
              <YTIcon :href="'#icon-fuzhi'"></YTIcon>
              <p>复制岗位</p>
            </div>
          </el-button>
          <el-button type="primary" size="small" style="width: 125px" @click="downloadPostSkill">
            <div>
              <YTIcon :href="'#icon-daochu'"></YTIcon>
              <p>导出岗位模型</p>
            </div>
          </el-button>
        </div>
        <el-table ref="table" :data="skillList" v-loading="skillLoading" element-loading-text="加载中" height="calc(100% - 44px)" class="yt-table">
          <el-table-column type="expand" width="20px">
            <template slot-scope="props">
              <el-table :data="props.row.skillLevels" class="yt-table skill-rank-table">
                <el-table-column label="技能评级" prop="name" show-overflow-tooltip />
                <el-table-column label="级别分数" show-overflow-tooltip>
                  <template slot-scope="scope">{{ scope.row.lowScore }} - {{ scope.row.highScore }} % </template>
                </el-table-column>
                <el-table-column label="级别描述" prop="description" show-overflow-tooltip />
              </el-table>
              <h4 style="margin-left: 10px; color: #000; margin-top: 10px">岗位信息子技能主题详情</h4>
              <el-table :data="props.row.subSkills" class="yt-table" style="margin-top: 0">
                <el-table-column label="子技能主题" prop="name" show-overflow-tooltip />
                <el-table-column label="子技能权重" prop="weight" show-overflow-tooltip />
                <el-table-column label="子技能比例" show-overflow-tooltip>
                  <template slot-scope="scope">{{ Math.round(scope.row.rate * 1000) / 10 }}%</template>
                </el-table-column>
                <el-table-column label="子技能描述" prop="description" show-overflow-tooltip />
                <el-table-column label="不合格评价 (<50%)" prop="unqualifiedRemark" show-overflow-tooltip />
                <el-table-column label="推荐课程" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <template v-for="(c, index) in scope.row.simpleCourses">
                      <span :key="c.courseId">{{ c.courseName }}{{ index < scope.row.simpleCourses.length - 1 ? ', ' : '' }}</span>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="主技能" prop="name" show-overflow-tooltip />
          <el-table-column label="技能描述" prop="description" show-overflow-tooltip />
          <el-table-column label="权重" prop="weight" show-overflow-tooltip />
          <el-table-column label="比例" show-overflow-tooltip>
            <template slot-scope="scope">{{ Math.round(scope.row.rate * 1000) / 10 }}%</template>
          </el-table-column>
          <el-table-column v-if="activeIndex !== '0'" label="操作" width="120px">
            <template slot-scope="scope">
              <span class="tool-button" @click="toUpdateSkill(scope.row)">编辑</span>
              <span class="tool-button" @click="toDeleteSkill(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <PostCUDialog ref="postCUDialog" @refresh="appendTreeNode" @updateNode="updateTreeNode" />
    <PostSkillConfigDialog ref="postSkillConfigDialog" @computeRate="computeRate" @refresh="getPostSkill(activePostData.id)" />
    <PostCopyDialog ref="postCopyDialog" @refresh="appendTreeNode" />
    <ConfirmDialog ref="postDeleteDialog" :title="'删除岗位'" @on-ok="deletePost">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该岗位，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="postSkillDeleteDialog" :title="'删除岗位技能'" @on-ok="deletePostSkill">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该岗位技能，删除后将不可恢复
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import PostCUDialog from '@components/common/dialog/PostCUDialog'
import PostSkillConfigDialog from '@components/common/dialog/PostSkillConfigDialog'
import PostCopyDialog from '@components/common/dialog/PostCopyDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import postApi from '@/api/post'
import newPostApi from '@/api/newPost'

export default {
  name: 'Post',
  components: { YTIcon, PostCUDialog, PostSkillConfigDialog, PostCopyDialog, ConfirmDialog },
  data() {
    return {
      activeIndex: '1',
      isPackUp: false,
      postKeyword: '', //岗位搜索关键词
      postFilterList: [],
      postData: [], //岗位数据
      postProps: {
        label: 'name'
      },
      activePostData: {},
      skillList: [], //岗位技能数据
      skillLoading: false,
      repeatList: [], //记录合并的行数
      deleteData: {}, //删除岗位数据
      deleteSkillData: {} //删除岗位技能数据
    }
  },
  mounted() {
    this.getPost()
  },
  methods: {
    selectMenu(index) {
      //切换企业/平台
      this.activeIndex = index
      this.getPost()
    },
    getPost() {
      postApi.getPostByType(this.activeIndex === '1').then(res => {
        this.postData = res.res
        if (this.postData.length > 0) {
          this.activePostData = this.postData[0]
          this.setCurrentKey(this.postData[0].id)
          this.getPostSkill(this.postData[0].id)
        } else {
          this.skillList = []
        }
      })
    },
    toFilterNodes() {
      this.postFilterList = []
      this.$refs['post-tree'].filter(this.postKeyword)
    },
    filterNode(value, data) {
      let result = true
      if (!value) {
        this.setCurrentKey(this.postData[0].id)
        this.handleNodeClick(this.postData[0])
      }
      result = data.name.indexOf(value) !== -1
      if (result) {
        this.postFilterList.push(data)
        if (this.postFilterList.length === 1) {
          this.setCurrentKey(data.id)
          this.handleNodeClick(data)
        }
      }
      return result
    },
    toCreatePost() {
      this.$refs.postCUDialog.open(true)
    },
    toUpdatePost(data) {
      this.$refs.postCUDialog.open(false, data)
    },
    toDeletePost(data) {
      this.deleteData = data
      this.$refs.postDeleteDialog.open()
    },
    deletePost() {
      postApi.deletePost(this.deleteData.id).then(res => {
        if (res.res === true) {
          this.$message.success('删除岗位成功')
          this.$refs['post-tree'].remove(this.deleteData)
        }
      })
    },
    toCopyPost() {
      this.$refs.postCopyDialog.open(this.activePostData.id)
    },
    appendTreeNode(data) {
      if (this.activeIndex !== '0') {
        this.$refs['post-tree'].append(data, null)
      }
    },
    updateTreeNode(data) {
      if (this.activeIndex !== '0') {
        let updateIndex = this.postData.findIndex(item => {
          return item.id === data.id
        })
        this.$set(this.postData, updateIndex, data)
        this.$forceUpdate()
      }
    },
    handleNodeClick(data) {
      this.activePostData = data
      this.getPostSkill(data.id)
    },
    setCurrentKey(postId) {
      this.$nextTick(() => {
        this.$refs['post-tree'].setCurrentKey(postId ? postId : 0)
      })
    },
    getPostSkill(id) {
      newPostApi.getPostSkill(id).then(res => {
        this.skillList = res.res
      })
    },
    toAddSkill() {
      this.$refs.postSkillConfigDialog.open(true, this.activePostData)
    },
    toUpdateSkill(row) {
      this.$refs.postSkillConfigDialog.open(false, this.activePostData, row)
    },
    toDeleteSkill(data) {
      this.deleteSkillData = data
      this.$refs.postSkillDeleteDialog.open()
    },
    deletePostSkill() {
      newPostApi.deletePostSkill(this.deleteSkillData.postSkillId).then(res => {
        if (res.res === true) {
          this.$message.success('删除岗位技能成功')
          this.getPostSkill(this.activePostData.id)
        }
      })
    },
    downloadPostSkill() {
      newPostApi.downloadPostSkill(this.activePostData.id).then(res => {
        this.$downloadFlow(res, '岗位模型', '.xls')
      })
    },
    computeRate(formData, callback) {
      let total = this.skillList.reduce((prev, cur) => {
        if (cur.abilityId !== formData.abilityId) {
          return prev + cur.weight
        } else {
          return prev
        }
      }, formData.weight)
      callback(formData.weight / total)
    }
  }
}
</script>

<style lang="less" scoped>
.yt-content {
  padding: 12px 20px 16px 20px;
}
.search {
  margin: 6px auto;
  padding: 0 7px;
  height: 32px;
}
::v-deep .el-input__suffix {
  right: 15px;
}
.yt-table {
  margin-top: 12px;
}
.skill-rank-table {
  ::v-deep .el-table__body {
    .el-table__row {
      height: 32px;
    }
    td {
      padding: 0;
    }
  }
}
</style>
